import React from 'react';
import './Search.css';
import UserList from '../searchComponents/UserList'

const users = [
  {
    photo: 'https://via.placeholder.com/50',
    name: 'Rushab Shah',
    followers: 10
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'Jane Doe',
    followers: 15
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  {
    photo: 'https://via.placeholder.com/50',
    name: 'John Smith',
    followers: 20
  },
  
];

export default function Search() {
  return (
    <div className="search-container">
      <div className="search-bar">
        <button className="back-button">
          <i className="fas fa-search"></i>
        </button>
        <input type="text" className="search-input" placeholder="Search..." />
      </div>
      <div className="search-body">
        {users.map((user, index) => (
          <UserList
            key={index}
            photo={user.photo}
            name={user.name}
            followers={user.followers}
          />
        ))}
      </div>
    </div>
  );
}
