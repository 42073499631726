import React from 'react';
import './Home.css';
import Post from '../postComponents/Post';

const dummyData = [
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
  {
    profileimage: 'https://via.placeholder.com/50',
    time: '2 hours ago',
    caption: 'This is a sample post caption.',
    image: 'https://via.placeholder.com/300',
    likes: 10,
    dislikes: 2,
    comments: 5
  },
];

function Home() {
  return (
    <div className="home-container">
      <div className="home-top-nav">
        <span className="home-nav-title">Home</span>
      </div>
      <div className="home-content">
        {dummyData.map((post, index) => (
          <Post
            key={index}
            profileimage={post.profileimage}
            time={post.time}
            caption={post.caption}
            image={post.image}
            likes={post.likes}
            dislikes={post.dislikes}
            comments={post.comments}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
