import React from 'react';
import './Profile.css';

export default function Profile() {
  const handleSettingsClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.crowdbuzztechnologies.crowdbuzz&hl=en_IN&gl=US';
  };

  return (
    <div className='profile-container'>
      <div className="top-nav">
        <div className="nav-title">Profile</div>
        <button className="settings-button" onClick={handleSettingsClick}>
          <i className="fas fa-cog"></i>
        </button>
      </div>
      <div className="profile">
        <div className="profile-pic">
          <img
            src="https://m.timesofindia.com/photo/104472511/104472511.jpg"
            alt="Profile Picture"
          />
        </div>
        <div className="profile-info">
          <h1 className="username">JohnDoe123</h1>
          <p className="handle">@johndoe123</p>
          <p className="bio">Web Developer | Explorer | Nature Lover</p>
          <div className="counts">
            <span className="points">
              5,432 <span className="label">Points</span>
            </span>
            <span>
              5,432 <span className="label">Followers</span>
            </span>
            <span>
              12,345 <span className="label">Following</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
