import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Post.css';
import { BiComment, BiDislike, BiLike } from 'react-icons/bi';

function Post(props) {
  const navigate = useNavigate();

  const handlePostClick = () => {
    navigate(`/comments/${props.id}`);
  };

  return (
    <div className="Post" onClick={handlePostClick}>
      <div className="post-link">
        <div className="post-card">
          <div className="user-info">
            <div className="user-details">
              <img src={props.profileimage} alt="Profile" />
              <div>
                <h3>Unknown</h3>
                <div className="time">{props.time}</div>
              </div>
            </div>
            <div className="options-menu">
              <i className="fas fa-ellipsis-h"></i>
            </div>
          </div>
          <div className="post-text">
            {props.caption}
          </div>
          <img src={props.image} alt="Post" />
          <div className="post-actions">
            <span>
            <BiLike className="action-icon" />
              {props.likes}
            </span>
            <span>
            <BiDislike className="action-icon" />
              {props.dislikes}
            </span>
            <span>
             <BiComment className="action-icon" />
              {props.comments}
            </span>
            <span>
              <i className="far fa-share-square"></i>
              Share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
