import React from 'react';
import './Signup.css';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon from react-icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation

function Signup() {
  return (
    <div className="signup-container">
      <div className="signup-form">
        <h2>Sign Up</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" className="form-control" placeholder="Enter your name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" className="form-control" placeholder="Enter your email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" className="form-control" placeholder="Enter your password" />
          </div>
          <div className="form-group">
            <label htmlFor="retype-password">Retype Password</label>
            <input type="password" id="retype-password" className="form-control" placeholder="Retype your password" />
          </div>
          <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
        </form>
        <p className="terms-text">
          By signing up, you accept our <Link to="/terms-and-conditions">Terms & Conditions</Link>.
        </p>
        <hr />
        <button className="btn btn-google btn-block">
          <FcGoogle size={24} /> Continue with Google
        </button>
        <div className="login-link">
          <p>Already have an account? <Link to="/login">Login</Link></p>
        </div>
      </div>
    </div>
  );
}

export default Signup;
