import React from 'react';
import './Login.css';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon from react-icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation

function Login() {
  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" className="form-control" placeholder="Enter your email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" className="form-control" placeholder="Enter your password" />
            <a href="#" className="forgot-password">Forgot Password?</a>
          </div>
          <button type="submit" className="btn btn-primary btn-block">Login</button>
        </form>
        <hr />
        <button className="btn btn-google btn-block">
          <FcGoogle size={24} /> Continue with Google
        </button>
        <div className="signup-link">
          <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
        </div>
      </div>
    </div>
  );
}

export default Login;
