import React from 'react';
import './Navbar.css';

export default function NavBar() {
  return (
    <div className="navbar">
      <div className="logo">
        {/* Logo image */}
        <img src="https://firebasestorage.googleapis.com/v0/b/cosmectsecretbase.appspot.com/o/logo-dark-banner.png?alt=media&token=dc07d952-7bc9-4a64-9a04-9578631a3e84" alt="Logo" className="logo-img" />
      </div>
      <nav className="nav-links">
        {/* Navigation links */}
        <a href="home" className="nav-item">
          <i className="fas fa-home"></i>
          <span className="nav-text">Home</span>
        </a>
        <a href="search" className="nav-item">
          <i className="fas fa-search"></i>
          <span className="nav-text">Search</span>
        </a>
        <a href="create" className="nav-item">
          <i className="fas fa-plus-circle"></i>
          <span className="nav-text">Create</span>
        </a>
        <a href="profile" className="nav-item">
          <i className="fas fa-user"></i>
          <span className="nav-text">Profile</span>
        </a>
      </nav>
    </div>
  );
}
