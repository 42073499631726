import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import './Privacy.css';

const Privacy = () => {
  const handleBackClick = () => {
    // Logic for handling back action can be added here
    console.log('Back button clicked');
  };

  return (
    <div>
      <nav className='nav-barr'>
        <FaArrowLeft 
          className="back-icon" 
          onClick={handleBackClick} 
        />
        <h1>Privacy Policy</h1>
        <div style={{ width: '20px' }}></div> {/* This div is just to balance the space on the right */}
      </nav>
      <main>
        <p><strong>Effective Date:</strong> 25-06-2024</p>

        <p>Welcome to Crowdbuzz! We value your privacy and are committed to protecting your personal information while providing a secure, anonymous platform for authentic self-expression. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to ensure your privacy and security.</p>

        <h2>1. Information We Collect</h2>

        <h3>a. Personal Information</h3>
        <ul>
          <li><strong>Registration Information:</strong> When you sign up for Crowdbuzz, we may collect your email address or phone number and a unique username.</li>
          <li><strong>Usage Information:</strong> We collect data about your interactions with our platform, including the content you post, your group memberships, and the messages you send.</li>
        </ul>

        <h3>b. Non-Personal Information</h3>
        <ul>
          <li><strong>Device Information:</strong> We collect information about the device you use to access Crowdbuzz, such as your IP address, operating system, and browser type.</li>
          <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience on our platform. You can manage your cookie preferences through your browser settings.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <ul>
          <li><strong>Provide and Improve Services:</strong> We use your information to operate and enhance the Crowdbuzz platform, ensuring a seamless and engaging user experience.</li>
          <li><strong>Ensure Security:</strong> We implement measures to protect your anonymity and prevent unauthorized access, ensuring a safe environment for all users.</li>
          <li><strong>Communication:</strong> We may use your email address to send you important updates about Crowdbuzz, including changes to our policies or services.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <ul>
          <li><strong>Anonymity:</strong> Your identity remains hidden when you interact on Crowdbuzz. We do not disclose your personal information to other users.</li>
          <li><strong>Service Providers:</strong> We may share information with third-party service providers who assist us in operating and improving Crowdbuzz. These providers are bound by strict confidentiality agreements.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights, safety, and the safety of others.</li>
        </ul>

        <h2>4. Your Choices</h2>
        <ul>
          <li><strong>Account Settings:</strong> You can update your account information and privacy settings through the Crowdbuzz app.</li>
          <li><strong>Data Deletion:</strong> You may request the deletion of your account and personal information by contacting our support team at <a href="mailto:crowdbuzz.company@gmail.com">crowdbuzz.company@gmail.com</a>.</li>
        </ul>

        <h2>5. Data Security</h2>
        <p>We implement robust security measures to protect your information from unauthorized access, alteration, and disclosure. Despite these efforts, no system can guarantee absolute security. We encourage you to use strong passwords and keep your account information secure.</p>

        <h2>6. Children's Privacy</h2>
        <p>Crowdbuzz is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If you believe a child has provided us with personal information, please contact us immediately.</p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of significant changes through the Crowdbuzz app or via email. Your continued use of Crowdbuzz after any changes indicates your acceptance of the updated policy.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p>
          <strong>Crowdbuzz Support Team</strong><br/>
          Email: <a href="mailto:crowdbuzz.company@gmail.com">crowdbuzz.company@gmail.com</a><br/>
          Address: Gayatri nagar, Hyderabad , Telangana, India - 500097
        </p>

        <p>Thank you for trusting Crowdbuzz with your privacy. We are committed to providing a secure and anonymous platform for you to express yourself freely. Join us today and discover the power of anonymous communication!</p>
      </main>
    </div>
  );
};

export default Privacy;
