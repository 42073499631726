import React from 'react';
import { BiLike, BiDislike, BiComment } from 'react-icons/bi';
import './Comments.css';

export default function Comments({ profileimage, userName, commentText, likeCount, dislikeCount, commentCount }) {
  return (
    <div className="comment">
      <img src={profileimage} alt="profile" className="profile-image" />
      <div className="comment-details">
        <p className="user-name">{userName}</p>
        <p className="comment-text">{commentText}</p>
        <div className="comment-actions">
          <div className="action-item">
            <BiLike className="action-icon" />
            <span className="action-count">{likeCount}</span>
          </div>
          <div className="action-item">
            <BiDislike className="action-icon" />
            <span className="action-count">{dislikeCount}</span>
          </div>
          <div className="action-item">
            <BiComment className="action-icon" />
            <span className="action-count">{commentCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
