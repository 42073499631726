import React from 'react';
import { BiArrowBack, BiSend } from 'react-icons/bi';
import Comments from '../postComponents/Comments';
import './Commentpage.css';

const dummyComments = [
  { userName: 'User1', commentText: 'This is the first comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 5, dislikeCount: 1, commentCount: 2 },
  { userName: 'User2', commentText: 'This is the second comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 3, dislikeCount: 0, commentCount: 1 },
  { userName: 'User3', commentText: 'This is the third comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 8, dislikeCount: 2, commentCount: 3 },
  { userName: 'User4', commentText: 'This is the fourth comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 2, dislikeCount: 1, commentCount: 0 },
  { userName: 'User5', commentText: 'This is the fifth comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 6, dislikeCount: 0, commentCount: 4 },
  { userName: 'User6', commentText: 'This is the sixth comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 4, dislikeCount: 2, commentCount: 2 },
  { userName: 'User7', commentText: 'This is the seventh comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 7, dislikeCount: 1, commentCount: 1 },
  { userName: 'User8', commentText: 'This is the eighth comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 1, dislikeCount: 0, commentCount: 0 },
  { userName: 'User9', commentText: 'This is the ninth comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 9, dislikeCount: 3, commentCount: 5 },
  { userName: 'User10', commentText: 'This is the tenth comment.', profileImage: 'https://via.placeholder.com/50', likeCount: 10, dislikeCount: 2, commentCount: 6 },
];

function Commentpage() {
  return (
    <div className="comment-page">
      <div className="top-nav">
        <button className="back-button">
          <BiArrowBack />
        </button>
        <div className="nav-title">Comments</div>
      </div>
      <div className="body">
        {dummyComments.map((comment, index) => (
          <Comments 
            key={index}
            profileimage={comment.profileImage}
            userName={comment.userName}
            commentText={comment.commentText}
            likeCount={comment.likeCount}
            dislikeCount={comment.dislikeCount}
            commentCount={comment.commentCount}
          />
        ))}
      </div>
      <div className="bottom-bar">
        <input type="text" className="edit-text" placeholder="Write a comment..." />
        <button className="send-button">
          <BiSend />
        </button>
      </div>
    </div>
  );
}

export default Commentpage;
