import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import './Terms.css';

const Terms = () => {
  const handleBackClick = () => {
    // Logic for handling back action can be added here
    console.log('Back button clicked');
  };

  return (
    <div className="terms-container">
      <nav className='nav-bar'>
        <FaArrowLeft 
          className="back-icon" 
          onClick={handleBackClick} 
        />
        <h1>Terms and Conditions</h1>
        <div style={{ width: '20px' }}></div> {/* This div is just to balance the space on the right */}
      </nav>
      <main className="terms-content">
        <h2>Welcome to Crowdbuzz</h2>
        <p>Welcome to Crowdbuzz! These Terms and Conditions outline the rules and guidelines for using our anonymous social media platform.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using Crowdbuzz, you agree to abide by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms, please do not use our platform.</p>

        <h2>2. Use of Crowdbuzz</h2>
        <p>You must be at least 13 years old to use Crowdbuzz. By using the platform, you agree:</p>
        <ul>
          <li>To use Crowdbuzz only for lawful purposes and in accordance with these terms.</li>
          <li>Not to engage in any activity that interferes with or disrupts the platform or its services.</li>
          <li>Not to violate the rights of others or infringe on intellectual property rights.</li>
        </ul>

        <h2>3. User Content</h2>
        <p>Users are solely responsible for the content they post on Crowdbuzz. By posting content, you grant Crowdbuzz a non-exclusive, royalty-free license to use, modify, and distribute your content on our platform.</p>

        <h2>4. Intellectual Property</h2>
        <p>The content, logos, and trademarks on Crowdbuzz are owned by Crowdbuzz and its licensors. You may not use, reproduce, or distribute any content from Crowdbuzz without permission.</p>

        <h2>5. Privacy</h2>
        <p>Your privacy is important to us. Please review our <a href="/privacy-policy">Privacy Policy</a> to understand how we collect, use, and protect your personal information.</p>

        <h2>6. Limitation of Liability</h2>
        <p>Crowdbuzz is provided "as is" without warranties of any kind. We are not liable for any damages resulting from the use or inability to use our platform.</p>

        <h2>7. Changes to Terms</h2>
        <p>We may update these Terms and Conditions from time to time. We will notify you of significant changes. Continued use of Crowdbuzz after changes indicates your acceptance of the updated terms.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
        <p>
          <strong>Crowdbuzz Support Team</strong><br/>
          Email: <a href="mailto:crowdbuzz.company@gmail.com">crowdbuzz.company@gmail.com</a><br/>
          Address: Gayatri nagar, Hyderabad , Telangana, India - 500097
        </p>

        <p>Thank you for using Crowdbuzz!</p>
      </main>
    </div>
  );
};

export default Terms;
