import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserList.css';

export default function UserList({ photo, name, followers }) {
  const navigate = useNavigate();

  const handleFollowClick = () => {
    navigate('/profile/kl');
  };

  return (
    <div className="user-profile" onClick={handleFollowClick}>
      <div className="user-photo">
        <img src={photo} alt={name} />
      </div>
      <div className="user-details">
        <h2>{name}</h2>
        <p>{followers} followers</p>
      </div>
      <button className="follow-button">
        Follow
      </button>
    </div>
  );
}
