import React, { useRef } from 'react';
import './Create.css';

function Create() {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
      // Handle the file upload here
    }
  };

  const handleCloseClick = () => {
    // Handle close button click logic here
    console.log('Close button clicked');
  };

  return (
    <div className="create-container">
      <div className="top-nav">
        <span className="nav-title">Share Anonymously</span>
        <div className="post-btn">Post</div>
      </div>
      <div className="body">
        <textarea className="input-field" placeholder="What's on your mind? Type here..."></textarea>
        <div className="bottom-nav">
          <span className="image-icon" onClick={handleImageClick}>
            <i className="fas fa-image"></i>
          </span>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
          />
          <span className="notification-icon">
            <i className="fas fa-bell"></i>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Create;
