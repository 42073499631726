import React from 'react';
import './Othersprofile.css';  // You can use the same CSS or create a new one if needed

export default function Othersprofile({ photo, username, handle, bio, points, followers, following, onFollow }) {
  return (
    <div className='profile-container'>
      <div className="top-nav">
        <div className="nav-title-op">Profile</div>
      </div>
      <div className="profile">
        <div className="profile-pic">
          <img
            src={photo}
            alt="Profile Picture"
          />
        </div>
        <div className="profile-info">
          <h1 className="username">{username}</h1>
          <p className="handle">@{handle}</p>
          <p className="bio">{bio}</p>
          <div className="counts">
            <span className="points">
              {points} <span className="label">Points</span>
            </span>
            <span>
              {followers} <span className="label">Followers</span>
            </span>
            <span>
              {following} <span className="label">Following</span>
            </span>
          </div>
          <button className="foll-button" onClick={onFollow}>
            Follow
          </button>
        </div>
      </div>
    </div>
  );
}
